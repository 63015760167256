<template>
	<div>
		<base-data-table
			:title="$t('users')"
			:headers="headers"
			:provider="$users"
			:fetch-function="onFetchUsers"
			:actions="actions"
			translate
			@view="onViewUser"
			@update="onUpdateUser"
			@delete="onDeleteUser"
			@click:add="onInitUser"
			@changeStatus="onChangeStatus"
		>
			<!-- SEARCH -->
			<template #search>
				<v-btn
					depressed
					height="48"
					color="primary"
					@click="onToggleSearch"
				>
					<v-icon v-if="search.isExpanded">mdi-close</v-icon>
					<v-icon v-else>mdi-filter</v-icon>
				</v-btn>
			</template>

			<template v-if="search.isExpanded" #extension>
				<div class="tw-bg-gray-200 tw-p-4 tw-mx-4 tw-rounded">
					<div class="tw-grid tw-gap-4 tw-grid-cols-2">
						<v-text-field
							v-model="search.form.iqamaCardNumber"
							:label="$t('card-iqama-number')"
							outlined
							clearable
							hide-details
							placeholder="e.g. 12345678"
						/>

						<v-text-field
							v-model="search.form.name"
							:label="$t('user-name')"
							outlined
							clearable
							hide-details
							placeholder="e.g. John Doe"
						/>

						<v-select
							v-model="search.form.status"
							:label="$t('statusText')"
							:items="[
								{ text: 'Active', value: 'active' },
								{ text: 'Inactive', value: 'deactive' },
							]"
							outlined
							multiple
							clearable
							hide-details
						/>

						<v-text-field
							v-model="search.form.phone"
							:label="$t('cell-phone')"
							outlined
							clearable
							hide-details
							placeholder="e.g. +966123456789"
						/>
					</div>
					<div
						class="tw-flex tw-justify-end tw-space-x-2 tw-items-end tw-mt-4"
					>
						<v-btn text color="primary" @click="onToggleSearch">
							<span class="tw-normal-case">{{ $t('close') }}</span>
						</v-btn>
						<v-btn text color="primary" @click="onResetSearch">
							<span class="tw-normal-case">{{ $t('reset') }}</span>
						</v-btn>
						<base-button
							:loading="search.isLoading"
							@click="onClickSearch"
						>
							{{ $t('actions.search') }}
						</base-button>
					</div>
				</div>
			</template>
			<!-- SEARCH END -->

			<template v-slot:[`item.serial`]="{ index }">
				{{ $users.perPage * ($users.currentPage - 1) + index + 1 }}
			</template>
			<template v-slot:[`item.status`]="{ item }">
				<div
					:class="item.status == 'active' ? 'tw-text-AA' : 'tw-text-72'"
					class="tw-capitalize tw-text-sm tw-font-semibold"
				>
					{{ item.status }}
				</div>
			</template>
			<template v-slot:[`item.phone`]="{ item }">
				<base-phone-number
					:phone="item.phone"
					class="tw-whitespace-nowrap tw-flex tw-items-center tw-space-s-3"
				>
					<template #default="{ phone, country }">
						<v-avatar v-if="country" size="20">
							<base-flag height="20" width="20" :code="country" />
						</v-avatar>
						<span>{{ phone }}</span>
					</template>
				</base-phone-number>
			</template>
			<template v-slot:[`item.activatedAt`]="{ item }">
				<div>
					{{
						item.activatedAt
							? $m(item.activatedAt).format('Do MMM YYYY')
							: 'Inactive'
					}}
				</div>
			</template>
		</base-data-table>

		<v-dialog
			v-model="userForm.dialog"
			persistent
			scrollable
			max-width="1198"
			content-class="tw-rounded-2xl tw-shadow-lg"
		>
			<UserForm
				v-if="userForm.dialog"
				:errors.sync="error"
				:onUpdate="onUpdate"
				:on-view.sync="onView"
				:user="userForm.data"
				:loading="userForm.isLoading"
				@onClose="onResetUserForm"
				@UpdateUser="handleUpdateUser"
				@createUser="handleCreateUser"
			/>
		</v-dialog>

		<v-dialog
			content-class="tw-rounded-3xl tw-bg-white"
			persistent
			max-width="676"
			v-model="confirmStatusDialog"
		>
			<v-card elevation="0" class="tw-p-0 tw-mx-0">
				<div class="tw-text-center tw-py-5 tw-px-6 lg:tw-py-10 lg:tw-px-9">
					<h1 class="tw-text-2xl tw-font-semibold">
						{{
							onChangeStatusData.status === 'active'
								? $t('deactivate-user')
								: $t('activate-user')
						}}
					</h1>
					<div
						class="tw-text-black tw-text-opacity-25 tw-mt-6 tw-mb-9 xl:tw-mt-8 xl:tw-mb-14 tw-text-center"
					>
						{{ $t('sure-changing-status') }}
					</div>
					<v-card-actions class="tw-flex tw-space-s-4">
						<v-btn
							depressed
							color="#DF9999"
							outlined
							@click="confirmStatusDialog = false"
							class="tw-h-14 tw-flex-1 tw-rounded-lg"
							><span class="tw-capitalize tw-text-lg tw-font-medium">{{
								$t('actions.cancel')
							}}</span></v-btn
						>
						<v-btn
							depressed
							color="#DF9999"
							class="tw-h-14 tw-flex-1 tw-rounded-lg"
							@click="handleChangeUserStatus"
							dark
							:loading="loading"
						>
							<span class="tw-capitalize tw-text-lg tw-font-medium">{{
								$t('actions.confirm')
							}}</span></v-btn
						>
					</v-card-actions>
				</div>
			</v-card>
		</v-dialog>

		<DialogDeleteConfirmation
			v-model="confirmDialog"
			:title="$t('user')"
			:id.sync="onDeleteId"
			:delete-function="onConfirmDeleteUser"
		/>
	</div>
</template>

<script>
import { get } from 'lodash'
import { STATUS } from '../consts'
import { mapGetters, mapActions } from 'vuex'
import { toFormData, omitEmpties, convertKeysToSnakeCase } from 'vuelpers'

import UserForm from '../components/forms/UserForm.vue'
import DialogDeleteConfirmation from '../components/dialogs/DialogDeleteConfirmation.vue'

const initialSearchForm = () => {
	return {
		iqamaCardNumber: '',
		name: '',
		phone: '',
		status: '',
	}
}

export default {
	name: 'Users',
	components: {
		UserForm,
		DialogDeleteConfirmation,
	},
	data() {
		return {
			search: {
				isLoading: false,
				isExpanded: false,
				form: initialSearchForm(),
			},
			userForm: {
				data: null,
				dialog: false,
				isLoading: false,
			},
			headers: [
				{
					text: 'headers.ns',
					align: 'start',
					value: 'serial',
				},
				{
					text: 'headers.iqamaNumber',
					align: 'start',
					value: 'id',
				},
				{
					text: 'headers.name',
					align: 'start',
					value: 'name',
				},
				{
					text: 'headers.email',
					value: 'email',
				},
				{ text: 'headers.phone', value: 'phone' },
				{ text: 'headers.activationDate', value: 'activatedAt' },
				{
					text: 'headers.companyName',
					value: 'compnayName',
					computed(item) {
						return get(item, `${item.role}.0.company.name`, '-')
					},
				},
				{ text: 'headers.role', value: 'role' },
				{ text: 'headers.status', value: 'status' },
				{ text: '', value: 'actions' },
			],
			error: {},
			userData: {},
			onView: false,
			onUpdate: false,
			loading: false,

			confirmDialog: false,
			onDeleteId: null,
			onDeactiveId: null,
			onChangeStatusData: {},
			confirmStatusDialog: false,
		}
	},
	computed: {
		...mapGetters('users', ['$users']),
		...mapGetters('auth', ['$currentUser']),
		actions() {
			return [
				{
					text: this.$t('view-details'),
					event: 'view',
					icon: 'mdi-eye-outline',
				},
				{
					text: this.$t('actions.editDetails'),
					event: 'update',
					icon: 'mdi-pencil-outline',
				},
				{
					icon: 'mdi-minus-box-outline',
					event: 'changeStatus',
					text: (v) => {
						return v.status === STATUS.ACTIVE
							? this.$t('actions.deactivate')
							: this.$t('actions.activate')
					},
				},
				{
					divider: true,
				},
				{
					color: 'error',
					text: this.$t('actions.delete'),
					event: 'delete',
					icon: 'mdi-delete-outline',
				},
			]
		},
	},
	methods: {
		...mapActions('users', [
			'fetchUsers',
			'deleteUser',
			'createUser',
			'updateUser',
			'changeStatusOfUser',
		]),
		...mapActions('permission', ['getPermissions']),
		onFetchUsers(query = {}) {
			return this.fetchUsers({
				page: this.$users.currentPage,
				perPage: this.$users.perPage,
				...omitEmpties(this.search.form),
				...query,
			})
		},
		async onConfirmDeleteUser(id) {
			const res = await this.deleteUser(id)
			await this.onFetchUsers()
			return res
		},
		onToggleSearch() {
			this.search.isExpanded = !this.search.isExpanded
			if (!this.search.isExpanded) this.onResetSearch()
		},
		onResetSearch() {
			this.search.form = initialSearchForm()
			this.onClickSearch()
		},
		async onClickSearch() {
			this.search.isLoading = true
			const { perPage } = this.$users
			await this.onFetchUsers({ page: 1, perPage })
			this.search.isLoading = false
		},
		onInitUser() {
			this.userForm.dialog = true
		},
		onUpdateUser(user) {
			this.userForm.data = user
			this.userForm.dialog = true
			this.onUpdate = true
		},
		onViewUser(user) {
			console.log(user)
			this.userForm.data = user
			this.userForm.dialog = true
			this.onView = true
		},
		async handleCreateUser(user) {
			const buyer = toFormData(
				{
					...user,
					buyerId:
						user.buyerId ?? this.$get(this.$currentUser, 'buyer.0.id'),
					iqamaCardExpDate: this.$m(user.iqamaCardExpDate).format(
						this.$t('yyyy-mm-dd-hh-mm-ss')
					),
				},
				{ convertCase: 'snake_case' }
			)
			this.userForm.isLoading = true
			let [err] = await this.createUser(buyer)
			this.userForm.isLoading = false

			if (err) {
				this.error = err.errors
				this.$toast.error(this.$t('unexpected-error-creating-user'))
				return
			}

			this.$toast.success(this.$t('user-created-successfully'))
			this.onResetUserForm()
		},
		async handleUpdateUser(user) {
			let excludeUser = omitEmpties(convertKeysToSnakeCase(user))
			delete excludeUser.buyerId
			let buyer = toFormData(
				{
					...excludeUser,
					iqamaCardExpDate: this.$m(excludeUser.iqamaCardExpDate).format(
						this.$t('yyyy-mm-dd-hh-mm-ss')
					),
				},
				{ convertCase: 'snake_case' }
			)
			this.userForm.isLoading = true
			let [err, res] = await this.updateUser(buyer)
			this.userForm.isLoading = false
			if (err) {
				console.log(err)
				if (err.statusCode == 422) {
					this.error = err.errors
					this.$toast.error(this.$t('unexpected-error-updating-user'))
				}
			} else {
				console.log(res)
				this.$toast.success(this.$t('user-updated-successfully'))

				this.onResetUserForm()
			}
		},
		onDeleteUser(user) {
			this.confirmDialog = true
			this.onDeleteId = user.id
		},
		onChangeStatus(user) {
			this.confirmStatusDialog = true
			this.onChangeStatusData = user
		},
		async handleChangeUserStatus() {
			this.loading = true

			const { id, status } = this.onChangeStatusData
			let data = toFormData(
				{
					id,
					status: status === 'active' ? 'deactive' : 'active',
				},
				{ _method: 'PATCH' },
				{ convertCase: 'snake_case' }
			)
			let [err] = await this.changeStatusOfUser(data)
			if (err) {
				if (err.statusCode == 422) {
					this.error = err.errors
					this.$toast.error(this.$t('unexpected-changing-status'))
				}
				this.loading = false
			} else {
				this.$toast.success(this.$t('user-status-changed-successfully'))
				this.confirmStatusDialog = false
				this.onResetUserForm()
				this.loading = false
			}
		},
		onResetUserForm() {
			this.userForm.data = {}
			this.userForm.dialog = false
			this.userForm.isLoading = false

			this.onUpdate = false
			this.onView = false
			this.error = {}
		},
	},
	created() {
		this.getPermissions()
	},
}
</script>
