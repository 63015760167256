<template>
	<v-form
		:readonly="onView"
		ref="form"
		lazy-validation
		@submit.prevent="validateUser"
	>
		<v-card class="tw-overflow-x-hidden tw-overflow-y-auto">
			<div
				class="tw-flex tw-justify-between tw-items-center tw-px-4 tw-pt-4 lg:tw-px-14 lg:tw-pt-9 tw-mb-3 lg:tw-mb-4"
			>
				<h2 class="tw-text-22 tw-font-semibold tw-text-black">
					{{
						onView
							? $t('viewUser')
							: isUpdate
							? $t('updateUser')
							: $t('createUser')
					}}
				</h2>

				<div v-if="onView" class="tw-flex tw-space-s-3 tw-ms-auto">
					<v-btn
						width="50"
						height="50"
						class="tw-rounded-md"
						depressed
						fab
						@click="onResetUserForm"
						color="#DF9999"
						dark
					>
						<v-icon color="tw-text-white">mdi mdi-close</v-icon>
					</v-btn>
				</div>

				<div v-if="isUpdate" class="tw-flex tw-space-s-3">
					<v-btn
						:loading="loading"
						fab
						dark
						depressed
						width="50"
						height="50"
						color="#F2CA51"
						class="tw-rounded-md"
						@click="validateUser"
					>
						<v-icon color="tw-text-white">mdi mdi-check</v-icon>
					</v-btn>
					<v-btn
						width="50"
						height="50"
						class="tw-rounded-md"
						depressed
						fab
						@click="onResetUserForm"
						color="#DF9999"
						dark
					>
						<v-icon color="tw-text-white">mdi mdi-close</v-icon>
					</v-btn>
				</div>
			</div>

			<v-card-text class="tw-px-4 lg:tw-px-14 lg:tw-py-9 tw-py-6">
				<v-row>
					<template v-if="$currentUser.isAdmin">
						<v-col cols="12">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t(`selectUserType`) }}
							</div>

							<v-radio-group
								v-model="mUser.role"
								hide-details="auto"
								@change="onChangeRole"
							>
								<div class="tw-grid tw-grid-cols-3 tw-gap-6">
									<div
										class="px-5 tw-flex tw-items-center tw-border tw-border-f0f tw-h-60p tw-rounded-md"
										v-for="type in userTypes"
										:key="type.value"
									>
										<v-radio
											color="warning"
											:label="$t(type.label)"
											:value="type.value"
										>
											<template #label>
												<span class="tw-text-black xl:tw-ms-8">
													{{ $t(type.label) }}
												</span>
											</template>
										</v-radio>
									</div>
								</div>
							</v-radio-group>
						</v-col>
						<v-col cols="12">
							<template v-if="mUser.role === userType.RESOURCE_USER">
								<div
									class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
								>
									{{ $t(`chooseSupplier`) }}
								</div>

								<BaseAutocomplete
									v-model="mUser.supplier"
									item-value="id"
									return-object
									item-text="representativeName"
									:items="$suppliers.data"
									:per-page="$suppliers.perPage"
									:total="$suppliers.total"
									:page="$suppliers.currentPage"
									:on-fetch="onFetchSuppliers"
									:rules="[rules.required($t('supplierText'))]"
									:filter="onFilterSuppliers"
									:loading="$suppliers.isRefetching"
									:form-ref="$refs.form"
									:readonly="onView"
									:placeholder="$t(`chooseSupplier`)"
								>
									<template #selection="{ item }">
										<div
											class="tw-flex tw-flex-col tw-text-base tw-text-black"
										>
											<span class="tw-font-medium">
												{{ item.representativeName }}({{
													item.company.name
												}})
											</span>
											<span class="tw-opacity-80">
												{{ item.company.email }}
											</span>
										</div>
									</template>
									<template #item="{ item }">
										<v-list-item-content>
											<v-list-item-title class="tw-font-medium">
												{{ item.representativeName }}({{
													item.company.name
												}})
											</v-list-item-title>
											<v-list-item-subtitle>
												{{ item.company.email }}
											</v-list-item-subtitle>
										</v-list-item-content>
									</template>
								</BaseAutocomplete>

								<!-- <v-autocomplete
									v-model="mUser.supplierId"
									flat
									dense
									outlined
									name="supplierId"
									class="user-input pa-0"
									hide-details="auto"
									item-value="id"
									item-text="company.name"
									background-color="#FBFBFB"
									:items="$suppliers.data"
									:rules="[rules.required($t('supplierText'))]"
									:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
									:error-messages="error.supplierId || []"
									:loading="isLoadingSuppliers"
									@change="error.supplierId = []"
								></v-autocomplete> -->
							</template>
							<template v-if="mUser.role === userType.BUYER_AFFLIATE">
								<div
									class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
								>
									{{ $t(`chooseBuyer`) }}
								</div>
								<BaseAutocomplete
									v-model="mUser.buyer"
									item-value="id"
									return-object
									item-text="representativeName"
									:items="$buyers.data"
									:per-page="$buyers.perPage"
									:total="$buyers.total"
									:page="$buyers.currentPage"
									:on-fetch="onFetchBuyers"
									:rules="[rules.required($t('buyerText'))]"
									:filter="onFilterBuyers"
									:form-ref="$refs.form"
									:readonly="onView"
									:placeholder="$t(`chooseBuyer`)"
								>
									<template #selection="{ item }">
										<div
											class="tw-flex tw-flex-col tw-text-base tw-text-black"
										>
											<span class="tw-font-medium">
												{{ item.representativeName }}({{
													item.company.name
												}})
											</span>
											<span class="tw-opacity-80">
												{{ item.company.email }}
											</span>
										</div>
									</template>
									<template #item="{ item }">
										<v-list-item-content>
											<v-list-item-title class="tw-font-medium">
												{{ item.representativeName }}({{
													item.company.name
												}})
											</v-list-item-title>
											<v-list-item-subtitle>
												{{ item.company.email }}
											</v-list-item-subtitle>
										</v-list-item-content>
									</template>
								</BaseAutocomplete>

								<!-- <v-autocomplete
									v-model="mUser.buyerId"
									flat
									dense
									outlined
									name="buyerId"
									hide-details="auto"
									class="user-input pa-0"
									item-value="id"
									item-text="company.name"
									background-color="#FBFBFB"
									:items="$buyers.data"
									:rules="[rules.required($t('buyerText'))]"
									:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
									:error-messages="error.buyerId || []"
									:loading="isLoadingBuyers"
									@change="error.buyerId = []"
								></v-autocomplete> -->
							</template>
						</v-col>
					</template>

					<v-col cols="12" md="4" sm="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('name-of-user') }}
						</div>
						<v-text-field
							outlined
							flat
							name="name"
							:rules="[rules.required($t('name'))]"
							class="user-input"
							hide-details="auto"
							v-model="mUser.name"
							:placeholder="$t('name-of-user')"
							background-color="#FBFBFB"
							dense
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:error-messages="error.name ? error.name[0] : []"
						/>
					</v-col>
					<v-col cols="12" md="4" sm="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('card-number-or-iqama') }}
						</div>
						<v-text-field
							outlined
							flat
							background-color="#FBFBFB"
							:rules="[rules.required($t('number'))]"
							class="user-input"
							v-model="mUser.iqamaCardNumber"
							hide-details="auto"
							placeholder="#### #### #### ####"
							dense
							name="cardNumber"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:error-messages="
								error.iqamaCardNumber ? error.iqamaCardNumber[0] : []
							"
						/>
					</v-col>
					<v-col cols="12" md="4" sm="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('iqamaCardExpDate') }}
						</div>
						<!-- <v-text-field
							outlined
							flat
							:rules="[rules.required('Email'), rules.email]"
							class=" user-input"
							hide-details="auto"
							placeholder="Enter buyer mail"
							dense
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							/>-->
						<v-menu
							ref="menu"
							v-model="menu"
							:close-on-content-click="false"
							:return-value.sync="mUser.iqamaCardExpDate"
							transition="scale-transition"
							offset-y
							allowed-dates
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									outlined
									flat
									:rules="[rules.required($t('date'))]"
									class="user-input"
									hide-details="auto"
									:placeholder="$t('iqamaCardExpDate')"
									background-color="#FBFBFB"
									dense
									name="date"
									:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
									:error-messages="
										error.iqamaCardExpDate
											? error.iqamaCardExpDate[0]
											: []
									"
									:value="formatDate"
									readonly
									v-bind="attrs"
									v-on="on"
								/>
							</template>
							<v-date-picker
								v-model="mUser.iqamaCardExpDate"
								no-title
								scrollable
							>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="menu = false">{{
									$t('actions.cancel')
								}}</v-btn>
								<v-btn
									text
									color="primary"
									@click="$refs.menu.save(mUser.iqamaCardExpDate)"
									>{{ $t('actions.ok') }}</v-btn
								>
							</v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" md="4" sm="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('phone') }}
						</div>
						<v-text-field
							outlined
							flat
							type="tel"
							name="phone"
							:rules="[rules.required($t('phone'))]"
							v-model="mUser.phone"
							class="user-input"
							background-color="#FBFBFB"
							hide-details="auto"
							:placeholder="$t('phoneNumber')"
							dense
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:error-messages="error.phone ? error.phone[0] : []"
						/>
					</v-col>
					<v-col cols="12" md="4" sm="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('emailAddress') }}
						</div>
						<v-text-field
							outlined
							flat
							type="email"
							name="email"
							:rules="[rules.required($t('email')), rules.email]"
							class="user-input"
							hide-details="auto"
							background-color="#FBFBFB"
							:placeholder="$t('enterEmailAddress')"
							dense
							v-model="mUser.email"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							:error-messages="error.email ? error.email[0] : []"
						/>
					</v-col>
					<v-col cols="12" md="4" sm="6">
						<div
							class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
						>
							{{ $t('user-status') }}
						</div>
						<v-select
							v-model="mUser.status"
							:items="['active', 'deactive']"
							:placeholder="$t('user-status')"
							:rules="[rules.required($t('user-status'))]"
							:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
							flat
							dense
							outlined
							class="user-input"
							hide-details="auto"
							background-color="#FBFBFB"
						/>
					</v-col>

					<template v-if="!onView">
						<v-col cols="12" md="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('password') }}
							</div>
							<v-text-field
								v-model="mUser.password"
								:rules="[rules.password]"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:type="isVisiblePassword ? 'text' : 'password'"
								:placeholder="$t('enter-password')"
								:append-icon="
									isVisiblePassword ? 'mdi-eye' : 'mdi-eye-off'
								"
								flat
								dense
								outlined
								class="user-input"
								hide-details="auto"
								background-color="#FBFBFB"
								@click:append="isVisiblePassword = !isVisiblePassword"
							/>
						</v-col>

						<v-col cols="12" md="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('password-confirmation') }}
							</div>
							<v-text-field
								v-model="mUser.passwordConfirmation"
								:rules="[
									(v) =>
										rules.match(v, mUser.password, $t('password')),
								]"
								:placeholder="$t('resetPassowrd.confirmPassword')"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:type="
									isVisiblePasswordConfirmation ? 'text' : 'password'
								"
								:append-icon="
									isVisiblePasswordConfirmation
										? 'mdi-eye'
										: 'mdi-eye-off'
								"
								flat
								dense
								outlined
								class="user-input"
								hide-details="auto"
								background-color="#FBFBFB"
								@click:append="
									isVisiblePasswordConfirmation =
										!isVisiblePasswordConfirmation
								"
							/>
						</v-col>
					</template>

					<v-col cols="12">
						<div class>
							<span class="tw-text-47 tw-font-medium">
								{{ $t('user-permission') }}
							</span>
							<span
								v-if="
									showUserPermissionError &&
									mUser.permissions.length == 0
								"
								class="red--text tw-ms-2 tw-text-xs"
							>
								{{ $t('user-permission') }} {{ $t('isRequired') }}</span
							>
							<v-chip-group
								:value="mUser.permissions"
								:rules="[rules.required($t('permissionText'))]"
								column
								multiple
							>
								<v-chip
									v-for="(permission, i) in [
										{ id: 'all', name: 'All' },
										...permissions,
									]"
									:key="i"
									:value="permission.id"
									:disabled="onView"
									:color="
										isCheckedPermission(permission) ? '#F2CA51' : ''
									"
									:class="
										!isCheckedPermission(permission)
											? 'tw-border tw-border-E9 tw-bg-white'
											: ''
									"
									label
									class="tw-mt-5"
									@click="onClickPermission(permission)"
								>
									<span
										:class="
											isCheckedPermission(permission)
												? 'tw-text-white'
												: 'tw-text-black tw-opacity-70'
										"
										class="tw-text-base"
									>
										{{ permission.name }}
									</span>
								</v-chip>
							</v-chip-group>
						</div>
					</v-col>
					<v-col cols="12">
						<h2 class="tw-text-47 tw-font-medium tw-me-4 tw-mt-4">
							{{ $t('copyOfCard') }}
						</h2>

						<BaseUpload
							v-model="mUser.copyOfCard"
							:readonly="onView"
							class="tw-mt-3"
							subtitle="max500Image"
							title="adminContracts.UploadCardCopy"
							@input="showBuyerAttachmentError = false"
						/>
						<span
							v-if="showBuyerAttachmentError"
							class="red--text tw-ms-2 tw-text-xs"
						>
							{{ $t('attachment-is-required') }}
						</span>
					</v-col>
				</v-row>
			</v-card-text>

			<v-card-actions
				v-if="isCreate"
				class="tw-mt-4 tw-flex tw-px-4 lg:tw-px-14 lg:tw-pb-9 tw-pb-6"
			>
				<v-btn
					outlined
					color="primary"
					height="58"
					class="tw-flex-1 tw-rounded-lg"
					text
					@click="onResetUserForm"
				>
					<span class="tw-text-lg tw-font-medium tw-capitalize tw-text-72">
						{{ $t('actions.cancel') }}
					</span>
				</v-btn>
				<v-btn
					type="submit"
					height="58"
					class="tw-flex-1 tw-font-medium tw-rounded-lg"
					depressed
					color="primary"
					:loading="loading"
				>
					<span
						class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
					>
						{{ $t('actions.submit') }}
					</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-form>
</template>

<script>
import UseFilePicker from '../../mixins/UseFilePicker'

import { omit } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { createFormMixin } from '@/mixins/form-mixin'
import { USER_TYPE, USER_TYPES } from '../../consts'
import { isImage, toAttachment, getFile } from '../../helpers'

export default {
	name: 'UserForm',
	mixins: [
		UseFilePicker,
		createFormMixin({
			rules: ['required', 'email', 'password', 'match'],
		}),
	],
	props: {
		user: Object,
		errors: {
			type: Object,
			default: () => ({}),
		},
		onView: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		user: {
			deep: true,
			immediate: true,
			handler(v) {
				this.mUser = this.initialUser(v || {})
				if (!this.$buyers.data.length) {
					this.isLoadingBuyers = true
					this.getBuyers().then(() => {
						this.isLoadingBuyers = false
					})
				}
				if (!this.$suppliers.data.length) {
					this.isLoadingSuppliers = true
					this.getSuppliers({
						// select: 'id',
						// includes: 'company|name',
					}).then(() => {
						this.isLoadingSuppliers = false
					})
				}
			},
		},
	},
	data() {
		return {
			buyers: [],
			mUser: {},
			isVisiblePassword: false,
			isVisiblePasswordConfirmation: false,

			userType: USER_TYPE,
			userTypes: USER_TYPES,
			showBuyerAttachmentError: false,
			showUserPermissionError: false,
			menu: false,
			isLoadingBuyers: false,
			isLoadingSuppliers: false,
			wasViewing: false,
		}
	},
	computed: {
		...mapGetters('users', ['$suppliers', '$buyers']),
		...mapGetters('permission', ['$permissions']),
		...mapGetters('auth', ['$currentUser']),
		error: {
			get() {
				return this.errors
			},
			set(v) {
				this.$emit('update:errors', v)
			},
		},
		isCreate() {
			return !this.mUser.id || (!this.isUpdate && !this.onView)
		},
		isUpdate() {
			return !!this.mUser.id && !this.onView
		},
		attachmentUrl() {
			return this.$image(this.$get(this.mUser, 'file.attachedLink'))
		},
		isImageAttachment() {
			return this.isImage(this.attachmentUrl)
		},
		formatDate() {
			return this.mUser.iqamaCardExpDate
				? this.$m(this.mUser.iqamaCardExpDate).format(
						this.$t('do-mmm-yyyy')
				  )
				: ''
		},
		mPermissionIds() {
			return this.mUser.permissions.filter((p) => p !== 'all')
		},
		permissionIds() {
			return this.permissions.map((p) => p.id)
		},
		permissions() {
			const role = this.mUser.role || this.$currentUser?.role || 'buyer'
			return this.$permissions[role] || []
		},
		isCheckedAllPermissions() {
			return this.permissionIds.length === this.mPermissionIds.length
		},
	},
	methods: {
		...mapActions('users', ['getBuyers', 'getSuppliers']),
		isImage,
		getFile,
		toAttachment,
		onChangeRole() {
			this.mUser.permissions =
				this.user && this.user.role === this.mUser.role
					? this.user.permissions.map((p) => p.id)
					: []
		},
		onFilterSuppliers(item, query, itemText) {
			return [itemText, item.company.name, item.company.email].some((text) =>
				text.toLowerCase().includes(query.toLowerCase())
			)
		},
		onFetchSuppliers(query = {}) {
			return this.getSuppliers({
				page: this.$suppliers.currentPage,
				perPage: this.$suppliers.perPage,
				...query,
			})
		},
		onFilterBuyers(item, query, itemText) {
			return [itemText, item.company.name, item.company.email].some((text) =>
				text.toLowerCase().includes(query.toLowerCase())
			)
		},
		onFetchBuyers(query = {}) {
			return this.getBuyers({
				page: this.$buyers.currentPage,
				perPage: this.$buyers.perPage,
				...query,
			})
		},
		initialUser(v = {}) {
			return {
				id: v?.id,
				name: v?.name,
				phone: v?.phone,
				email: v?.email,
				status: v?.status || 'active',
				iqamaCardNumber: v?.iqamaCardNumber,
				iqamaCardExpDate: v?.iqamaCardExpDate,
				copyOfCard: this.toAttachment(
					Array.isArray(v?.copyOfCard) ? v.copyOfCard[0] : v?.copyOfCard
				),
				role: v?.role || this.$currentUser?.role || 'buyer',
				permissions: (v?.permissions || []).map((p) => p.id),
				buyer: this.$get(v, 'buyer.0'),
				supplier: this.$get(v, 'supplier.0'),
				password: '',
				passwordConfirmation: '',
			}
		},
		isCheckedPermission({ id }) {
			if (id === 'all') return this.isCheckedAllPermissions
			return this.mUser?.permissions?.includes(id)
		},
		onToggleAllPermissions() {
			this.mUser.permissions = !this.isCheckedAllPermissions
				? [...this.permissionIds]
				: []
		},
		onClickPermission({ id }) {
			if (id === 'all') return this.onToggleAllPermissions()

			const isChecked = this.isCheckedPermission({ id })
			this.mUser.permissions = isChecked
				? this.mUser.permissions.filter((v) => v !== id)
				: this.mUser.permissions.concat([id])
		},
		onResetUserForm() {
			if (this.wasViewing) {
				this.wasViewing = false
				this.$emit('update:on-view', true)
				return
			}

			this.$refs.form.reset()
			this.showBuyerAttachmentError = false
			this.showUserPermissionError = false

			this.$emit('onClose')
		},
		validateUser() {
			if (!this.$refs.form.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}

			if (!this.mUser.permissions.length) {
				this.showUserPermissionError = true
				return
			}

			if (this.isUpdate && !this.mUser.copyOfCard) {
				this.showBuyerAttachmentError = true
				return
			}

			const { role, copyOfCard, buyer, supplier } = this.mUser

			const body = {
				...omit(this.mUser, 'buyer', 'supplier', 'copyOfCard'),
				permissions: this.mPermissionIds,
				file: this.getFile(copyOfCard?.file),
				buyerId:
					role === 'buyer'
						? buyer?.id || this.$currentUser?.buySup?.id
						: null,
				supplierId:
					role === 'supplier'
						? supplier?.id || this.$currentUser?.buySup?.id
						: null,
			}

			if (this.isUpdate) {
				this.$root.confirmation = {
					title: this.$t('update-confirmation'),
					message: `Are you sure to update this user Information?`,
					handler: () => this.$emit('UpdateUser', body),
				}
				return
			}

			this.$root.confirmation = {
				title: this.$t('create-confirmation'),
				message: `Are you sure to Create this User?`,
				handler: () => {
					this.$emit('createUser', body)
				},
			}
		},
		onUpdateFromView() {
			this.wasViewing = true
			this.$emit('update:on-view', false)
			this.showBuyerAttachmentError = false
			this.showUserPermissionError = false
		},
	},
}
</script>

<style>
.user-input.v-text-field--outlined fieldset {
	color: #f0f0f0 !important;
}
.user-input input::placeholder {
	color: black !important;
	opacity: 0.25;
	font-size: 16px !important;
}
</style>
